<template>
  <div class="trained-ai-detail-training-detail-body">
    <div class="trained-ai-detail-training-detail-body-top">
      <div
        class="trained-ai-detail-training-detail-tab"
        :style="{ '--tabs': tabs.length }"
      >
        <tab-list
          :tabs="tabs"
          :activeTabProps="activeTabProps"
          @change-tab="changeTab"
        />
      </div>
      <div v-if="!isSaved" class="trained-ai-detail-training-detail-menu">
        <text-menu-list
          :menus="menus"
          @menu-click="$emit('delete-trained-ai')"
        />
      </div>
    </div>
    <components
      :is="displayType"
      :accontInfo="accountInfo"
      :trainedAi="trainedAi"
      :featureImportanceScatter="featureImportanceScatter"
      :activeTab="activeTab"
      :pageName="displayType"
      :selectedColumnIndex="selectedColumnIndex"
      :textMining="textMining"
      :threshold="threshold"
      :reversePositive="reversePositive"
      :testDatasetInfo="testDatasetInfo"
      :regressionGraph="regressionGraph"
      @change-test-dataset-page="$emit('change-test-dataset-page', $event)"
      @download-test-dataset="$emit('download-test-dataset', $event)"
      @change-threshold="$emit('change-threshold', $event)"
      @reverse-positive="$emit('reverse-positive', $event)"
      @load-text-mining="$emit('load-text-mining', $event)"
      @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
      @change-tab="changeTab"
      @select-regression-graph="$emit('select-regression-graph', $event)"
      @download-confusion-matrix="$emit('download-confusion-matrix', $event)"
    />
  </div>
</template>

<script>
import tabList from '@/components/molecules/tab-list'
import textMenuList from '@/components/molecules/text-menu-list'
import trainedAiDetailClassification from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-classification'
import trainedAiDetailImages from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-images'
import trainedAiDetailNaturalLanguage from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-natural-language'
import trainedAiDetailRegression from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-regression'
import trainedAiDetailTimeseries from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-timeseries'
import trainedAiDetailOptimization from '@/components/organisms/trained-ai-detail/details-type/trained-ai-detail-optimization'

import { getModelType } from '@/lib/trainedAI.js'

export default {
  components: {
    tabList,
    textMenuList,
    classification: trainedAiDetailClassification,
    images: trainedAiDetailImages,
    naturalLanguage: trainedAiDetailNaturalLanguage,
    regression: trainedAiDetailRegression,
    timeseries: trainedAiDetailTimeseries,
    optimization: trainedAiDetailOptimization
  },
  props: {
    accountInfo: Object,
    trainedAi: Object,
    recipeType: String,
    isSaved: Boolean,
    selectedColumnIndex: Number,
    textMining: Object,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object,
    regressionGraph: Object
  },
  data() {
    return {
      activeTab: 'detail',
      activeTabProps: 0
    }
  },
  computed: {
    modelType() {
      return getModelType(this.recipeType)
    },
    displayType() {
      if (this.trainedAi?.summary?.is_optimization) {
        return 'optimization'
      } else {
        return this.modelType
      }
    },
    tabs() {
      let returnArray = []
      const summary = {
        name: this.$t('trainedAiDetails.tabs.summary'),
        value: 'detail',
        iconName: 'detail'
      }
      const expectedEffect = {
        name: this.$t('trainedAiDetails.tabs.expectedEffect'),
        value: 'expectedEffect',
        iconName: 'expectedEffect'
      }
      const accuracy = {
        name: this.$t('trainedAiDetails.tabs.metrics'),
        value: 'accuracy',
        iconName: 'accuracy'
      }
      const featureImportance = {
        name: this.$t('trainedAiDetails.tabs.featureImportance'),
        value: 'featureImportance',
        iconName: 'featureImportance'
      }
      const info = {
        name: this.$t('trainedAiDetails.tabs.trainingInfo'),
        value: 'trainedInfo',
        iconName: 'trainedInfo'
      }
      const textMining = {
        name: this.$t('trainedAiDetails.tabs.wordCloud'),
        value: 'textMining',
        iconName: 'textmining'
      }
      const classicTestData = {
        name: this.$t('trainedAiDetails.tabs.testData'),
        value: 'classicTestData',
        iconName: 'testDataset'
      }
      const clusteringResult = {
        name: this.$t('clustering.result.tab.result'),
        value: 'clusteringResult',
        iconName: 'clusteringResult'
      }
      const clusteringDistribution = {
        name: this.$t('clustering.result.tab.distribution'),
        value: 'clusteringDistribution',
        iconName: 'clusteringDistribution'
      }
      const imageTestData = {
        name: this.$t('trainedAiDetails.tabs.testData'),
        value: 'imageTestData',
        iconName: 'testDataset'
      }
      const dependency = {
        name: this.$t('trainedAiDetails.tabs.dependency'),
        value: 'dependency',
        iconName: 'featureImportance'
      }
      if (this.checkOptimization) {
        // 最適化のタブ表示
        // 概要 最適化 精度 重要度 テストデータ結果 学習情報
        const optimization = {
          name: this.$t('trainedAi.optimization.table.result.title'),
          value: 'optimizationResult',
          iconName: 'optimization'
        }
        returnArray = [summary, optimization, accuracy, featureImportance]
        if (this.trainedAi?.result?.testData) {
          returnArray.push(classicTestData)
        }
        returnArray.push(info)
      } else {
        switch (this.trainedAi?.summary.type) {
          case 'DEEP':
          case 'FINETUNING_DEEP':
            // ディープラーニングのタブ表示
            // 概要 期待される効果 精度 (テスト結果) 学習情報
            returnArray = [summary, expectedEffect, accuracy]
            if (this.trainedAi.result?.testData) {
              returnArray.push(imageTestData)
            }
            returnArray.push(info)
            break
          case 'CONVERT_DATASET':
            // MatrixFlow内部でしか利用されていない結果表示
            returnArray = [
              summary,
              {
                name: this.$t('trainedAiDetails.tabs.convertResult'),
                value: 'convertResult',
                iconName: 'dataset'
              },
              info
            ]
            break
          case 'CLASSIFICATION':
            /**
             * 以下の順番となるtab用のarrayを作成する
             * ※()で表記しているものは存在しない場合がある
             *  - summary, expectedEffect, accuracy, textMining, featureImportance, classicTestData, trainedInfo
             */
            returnArray = [summary, expectedEffect, accuracy]
            if (this.trainedAi?.summary?.textMining != null) {
              returnArray.push(textMining)
            }
            if (
              this.trainedAi?.summary?.featureImportance &&
              Object.keys(this.trainedAi.summary?.featureImportance).length > 0
            ) {
              returnArray.push(featureImportance)
            }
            if (this.trainedAi.result?.testData) {
              returnArray.push(classicTestData)
            }
            returnArray.push(info)
            break
          case 'REGRESSION':
            // 回帰のタブ表示
            // 概要 期待される効果 精度 重要度 テスト結果 学習情報
            returnArray = [summary, expectedEffect, accuracy, featureImportance]
            if (this.trainedAi?.result?.testData) {
              returnArray.push(classicTestData)
            }
            returnArray.push(info)
            break
          case 'TIME':
            // 時系列のタブ表示
            // 概要 期待される効果 精度 学習情報
            returnArray = [summary, expectedEffect, accuracy]
            if (this.trainedAi?.trainConfig?.data) {
              returnArray.push(info)
            }
            break
          case 'TIME_TRANSFORMER':
            // MFTransformerのタブ表示
            // 概要
            returnArray = [summary]
            break
          case 'TIME_TRANSFORMER2':
            // MFTransformerV2のタブ表示
            // 概要, 列情報
            returnArray = [
              summary,
              {
                name: this.$t('trainedAiDetails.tabs.columnInfo'),
                value: 'columnInfo',
                iconName: 'trainedInfo'
              },
              dependency
            ]
            break
          case 'NO_ACCURACY':
            returnArray = [info]
            if (this.trainedAi?.summary?.textMining != null) {
              returnArray.unshift(textMining)
            }
            break
          // clusteringResult, clusteringDistribution, textMining, infoの順に並ぶ
          case 'CLUSTERFLOW':
            returnArray = [info]
            if (this.trainedAi?.summary?.textMining != null) {
              returnArray.unshift(textMining)
            }
            returnArray.unshift(...[clusteringResult, clusteringDistribution])
            break
          default:
            returnArray = [summary]
            break
        }
      }
      const res = returnArray.map((item, index) => {
        return {
          id: index,
          ...item
        }
      })
      return res
    },
    menus() {
      return [
        {
          id: 0,
          name: this.$t('training.result.deleteButton'),
          value: 'reTrain',
          iconName: 'delete'
        }
      ]
    },
    featureImportanceScatter() {
      return this.trainedAi?.featureImportanceScatter
    }
  },
  methods: {
    // TODO activeTabをViewsに移動したい
    changeTab(e) {
      this.activeTab = e
      this.activeTabProps = this.tabs.find((tab) => {
        return tab.value === e
      }).id
      this.$emit('change-tab', e)
    }
  }
}
</script>

<style lang="scss">
.trained-ai-detail-training-detail {
  &-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: $space-small;
    height: 100%;
    &-top {
      display: flex;
      justify-content: space-between;
      padding: 0 $space-medium;
    }
  }
  &-title-sub {
    margin: 0 0 $space-base;
  }
}
</style>
