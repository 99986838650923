<template>
  <trained-ai-detail
    :sidebar="sidebar"
    :accountInfo="accountInfo"
    :body="body"
    :popup="popup"
    :headerTabs="headerTabs"
    :trainedAi="trainedAi"
    :loading="detailLoading || loadingProjectList"
    :featureImportanceScatter="featureImportanceScatter"
    :notFound="notFound"
    :disableClick="disableClick"
    :loadChangeColumn="loadChangeColumn"
    :checkOptimization="checkOptimization"
    :threshold="threshold"
    :reversePositive="reversePositive"
    :testDatasetInfo="testDatasetInfo"
    @change-description="changeDescription($event)"
    @change-tab="changeTab($event)"
    @close-modal="closePopup($event)"
    @delete="deleteTrainedAi($event)"
    @delete-optimization="deleteOptimizationComfirm"
    @input-edit-form="inputEditForm($event)"
    @menu-click="menuClick($event)"
    @load-feature-importance="loadFeatureImportance"
    @check-service="showPopup('deleteServiceCheck')"
    @change-column="changeColumn($event)"
    @save-edit-optimization-form="saveEditOptimizationForm"
    @show-detail-optimization="loadOptimizationResult"
    @show-delete-optimization="showPopup('deleteOptimization')"
    @change-page="changePageOptimization"
    @change-test-dataset-page="changePageTestDataset"
    @change-filter-value="changeFilterValue"
    @download-result="downloadResult"
    @download-test-dataset="downloadTestDataset"
    @change-threshold="changeThreshold"
    @reverse-positive="changePositive"
    @load-text-mining="loadTextMining"
    @change-tab-text-mining="changeTabTextMining"
    @input-clustering-setting="inputClusteringSetting"
    @download-clustering-result="downloadClustering"
    @download-confusion-matrix="downloadConfusionMatrix"
    @change-clustering-distribution-column="changeClusteringDistributionColumn"
    @toggle-clustering-show-dimension="toggleClusteringShowDimension"
    @select-regression-graph="loadRegressionGraphDetail"
    @load-inference-info="loadInferenceListInfo"
    @show-inference="showInferenceResult"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { trainedAIValidator } from '@/lib/validator/trainedAI.js'
import { optimizationConditionsValidator } from '@/lib/validator/optimizationConditions.js'
import {
  TEXT_MINING_TYPE,
  TEXT_MINING_PAGE_TYPE,
  TEXT_MINING_PAGE_TYPE_TO_TYPE,
  textMiningResultSetting
} from '@/lib/text-mining'
import { DEEP_RECIPE_TYPE } from '@/lib/training.js'
import setMountedTimer from '@/mixin/set-mounted-timer'
import { download, base64ToBytes } from '@/lib/download'

import trainedAiDetail from '@/components/templates/trained-ai-detail.vue'

export default {
  name: 'ViewTrainingDetail',
  components: {
    trainedAiDetail
  },
  mixins: [setMountedTimer],
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('project', ['loadingProjectList', 'projectList']),
    ...mapGetters('customblock', ['customblockList']),

    projectId() {
      if (this.$route.params.projectId) {
        return parseInt(this.$route.params.projectId)
      }
      return null
    },
    trainedAIId: function () {
      return this.$route.params.id
    },
    notFoundProject() {
      if (this.projectId === null) {
        return false
      } else {
        return !(this.projectId in this.projectList)
      }
    },
    notFoundAi() {
      return !this.trainedAi
    },
    notFound() {
      return this.notFoundProject || this.notFoundAi
    },
    loading() {
      return this.detailLoading || this.loadingProjectList
    },
    disableClick() {
      return (
        this.submitDeleting ||
        this.body.pageBody.optimizationInfo.submitDeletingOptimization ||
        this.body.pageBody.optimizationInfo.download.downloadLoading
      )
    },
    sidebar() {
      return {
        // サイドバーに表示する情報
        project: {
          name: '退職予測',
          description:
            'このプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入りますこのプロジェクトの説明が入ります'
        },
        activeLink: 'model'
      }
    },
    checkOptimization() {
      return this.trainedAi?.summary?.is_optimization
    },
    isImageType() {
      const recipeType = this.trainedAi.summary.type
      return DEEP_RECIPE_TYPE.indexOf(recipeType) !== -1
    }
  },
  data() {
    return {
      trainedAi: null,
      detailLoading: false,
      featureImportanceScatter: null,
      headerTabs: {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      },
      body: {
        editInfo: {
          trainedAIFormValidate: {
            duplicate: undefined
          },
          editLoading: false
        },
        // ページ上部に表示する情報
        pageTop: {
          summary: {
            title: '学習済みAI一覧',
            description:
              'これはデモデータです。デモデータを使ってマトリックスフローの使い方に慣れましょう。AIの学習に使用できます。大体６０文字。これはデモデータです。デモデータを使ってマトリックスフローの使い方に慣れましょう。AIの学習に使用できます。大体６０文字。'
          }
        },
        pageBody: {
          tabItem: {},
          activeTab: 'detail',
          activeMenu: '',
          selectedColumnIndex: 0,
          optimizationInfo: {
            loadOptimizationDetail: false,
            loadOptimizationPaging: false,
            loadOptimizationConditionsUpdate: false,
            submitDeletingOptimization: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            },
            optimizationFormValidate: {
              duplicate: undefined
            }
          },
          optimizationResult: {
            optimizationId: null,
            result: {},
            columnRanges: [],
            filters: [],
            limit: 50,
            total: 0,
            inPageNumber: 1
          },
          optimizationConditions: [],
          convertDatasetInfo: {
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            }
          },
          inferenceListInfo: {
            loading: false,
            inferenceList: []
          },
          textMining: textMiningResultSetting(),
          isNotAvailableCustomblock: false,
          clusteringSetting: {
            setting: {
              resultId: null,
              inPageNumber: 1,
              limit: 50,
              sort: 'classAsc',
              showDimension: false
            },
            loadingTable: false,
            loadingDistribution: false,
            download: {
              downloading: false,
              downloadLoading: false,
              downloadComp: false
            },
            distributionSetting: [
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              },
              {
                xTarget: null,
                yTarget: null
              }
            ]
          },
          clusteringResult: {
            inputColumns: [],
            classes: [],
            total: null,
            list: [],
            listIndexClass: []
          },
          clusteringDistributions: [],
          regressionGraph: {
            graph: {
              list: [],
              dataCount: null
            },
            graphDetail: {},
            selectedRegressionData: null,
            loadGraph: false,
            loadGraphDetail: false,
            error: null
          }
        }
      },
      popup: {
        // ポップアップの情報
        deleteTrainedAi: {
          title: '本当に削除してもよろしいですか？',
          trainedAi: ''
        },
        showPopup: [] // 現在表示しているポップアップ
      },
      submitDeleting: false,
      loadChangeColumn: false,
      threshold: 0.5,
      reversePositive: false,
      testDatasetInfo: {
        limit: 50, // 推論結果の一度に帰ってくる件数
        inPageNumber: 1, // テストデータに対する推論結果のページ番号（1始まり）
        waitTable: false,
        download: {
          downloading: false,
          downloadLoading: false,
          downloadComp: false
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      if (to.params.textMiningType != null) {
        vm.body.pageBody.textMining.fromDashboad = true
      }
      await vm.fetchCustomblocks()
      await vm.loadDetail(to.params.id)
      vm.loadFeatureImportance()
      if (to.params.textMiningType != null) {
        await vm.textMiningFromDashboadSetting(to.params.textMiningType)
      }
      if (vm.trainedAi.summary.type === 'REGRESSION') {
        vm.loadRegressionGraph()
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.loadDetail(to.params.id)
    this.loadFeatureImportance()
  },
  methods: {
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('trainedAi', [
      'deleteTrainedAIs',
      'updateTrainedAI',
      'fetchTimeseriesDetail',
      'fetchTrainedAiDetail',
      'fetchOptimizationResultById',
      'deleteOptimization',
      'fetchOptimizationResult',
      'fetchOptimizationConditionsList',
      'downloadConvertDatasetResult',
      'downloadOptimizationResult',
      'updateOptimzationConditions',
      'getTrainedTextMining',
      'downloadTestDatasetResult',
      'downloadClusteringResult',
      'fetchClusteringResult',
      'fetchClusteringDistribution',
      'fetchRegressionGraph',
      'fetchRegressionGraphDetail',
      'downloadModel'
    ]),
    ...mapActions('services', ['deleteServices']),
    ...mapActions('customblock', ['fetchCustomblocks']),

    async loadDetail(id) {
      this.detailLoading = true

      const res = await this.fetchTrainedAiDetail({
        id,
        columnIndex: this.body.pageBody.selectedColumnIndex
      })

      // resultsPropに名前を付加

      const getCustomBlockName = (customblockId) => {
        const entry = this.customblockList?.find(
          (item) => item.customblock_id === customblockId
        )
        if (entry?.name != null) {
          return entry.name
        } else {
          this.body.pageBody.isNotAvailableCustomblock = true
          return this.$t('customblock.error.unknownBlock')
        }
      }

      if (!(res?.result?.layer_info == null)) {
        res?.result?.layer_info.forEach((item) => {
          const name = item.name
          if (name === 'customblock') {
            item.displayName = getCustomBlockName(item.customblock_id)
            return
          }
          item.displayName = this.$t('recipe.layerNames.' + name)
        })
      }

      this.trainedAi = res

      if (this.trainedAi.summary.is_optimization) {
        await this.loadOptimizationConditions()

        if (
          this.trainedAi.summary?.latestOptimizationConditions &&
          Object.keys(this.trainedAi.summary.latestOptimizationConditions)
            .length > 0
        ) {
          await this.loadOptimizationResult(
            this.trainedAi.summary.latestOptimizationConditions.id
          )
        }
      }

      if (this.trainedAi.summary?.textMining != null) {
        this.setTextMiningParams()
        if (this.trainedAi.summary.type === 'NO_ACCURACY') {
          this.body.pageBody.activeTab = 'textMining'
        }
      }

      if (this.trainedAi.summary.type === 'CLUSTERFLOW') {
        this.body.pageBody.activeTab = 'clusteringResult'
        this.body.pageBody.clusteringSetting.setting.resultId =
          this.trainedAi.summary.clusteringInfo.resultList[0].id

        this.loadClusteringResult()
      }

      // テスト結果タブの表示件数調整
      await this.loadProjectDetail(this.projectId)
      if (this.isImageType) {
        this.testDatasetInfo.limit = 15
      } else {
        this.testDatasetInfo.limit = 50
      }

      this.detailLoading = false
    },
    showPopup(e) {
      // ポップアップを表示
      if (this.popup.showPopup.length > 0) {
        this.popup.showPopup = []
        this.popup.showPopup.push(e)
      } else {
        this.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.popup.showPopup = this.popup.showPopup.filter((n) => n !== e)
    },
    changeTab(e) {
      if (this.checkOptimization) {
        this.resetOptimizationResult()
        if (e === 'detail') {
          this.loadOptimizationResult(
            this.trainedAi.summary.latestOptimizationConditions.id
          )
        }
      }
      this.body.pageBody.activeTab = e
    },
    async menuClick(e) {
      this.body.pageBody.activeMenu = e.activeMenu
      if (e.activeMenu === 'delete') {
        this.showPopup('deleteTrainedAi')
      } else if (e.activeMenu === 'inference') {
        if (this.$route.params.projectId) {
          const query = {
            trainedAiId: this.$route.params.id
          }
          if (this.checkOptimization) {
            query.isOptimization = true
          }

          this.$router.push({
            name: 'inferenceProject',
            params: { projectId: this.$route.params.projectId },
            query: { ...query }
          })
        } else {
          this.$router.push({
            name: 'inference'
          })
        }
      } else if (e.activeMenu === 'service') {
        if (this.$route.params.projectId) {
          this.$router.push({
            name: 'serviceProjectList',
            params: { projectId: this.$route.params.projectId }
          })
        } else {
          this.$router.push({
            name: 'serviceList'
          })
        }
      } else if (e.activeMenu === 'downloadModel') {
        try {
          await this.downloadModel({
            trainedAiId: this.$route.params.id
          })
        } catch (e) {
          this.showPopup('modelDownloadError')
        }
      }
    },
    async loadFeatureImportance() {
      const res = await this.$sendMessageAndReceive({
        action: 'getTrainedAIScatter',
        id: this.$route.params.id,
        columnIndex: this.body.pageBody.selectedColumnIndex
      })
      if (res.status !== 'error') {
        this.featureImportanceScatter = res.result
      }
    },
    async deleteTrainedAi(target) {
      this.submitDeleting = true
      try {
        await this.deleteTrainedAIService()
        await this.deleteTrainedAIs([target])
      } finally {
        if (this.projectId) {
          await this.loadProjectDetail(this.projectId)
        }
        this.submitDeleting = false
      }
      this.$router.push({ name: 'trainedAiList' })
    },
    async deleteTrainedAIService() {
      if (this.trainedAi.summary.services.length > 0) {
        const ids = this.trainedAi.summary.services.map((x) => x.serviceId)
        await this.deleteServices({
          serviceType: 'infer',
          projectId: this.projectId,
          payload: ids
        })
      }
    },
    async deleteOptimizationComfirm(id) {
      const optimizationInfo = this.body.pageBody.optimizationInfo
      optimizationInfo.submitDeletingOptimization = true
      try {
        await this.deleteOptimization({ id })

        if (id === this.trainedAi.summary.latestOptimizationConditions.id) {
          const res = await this.fetchTrainedAiDetail({
            id: this.trainedAIId,
            columnIndex: this.body.pageBody.selectedColumnIndex
          })
          this.trainedAi = res
        }
        await this.loadOptimizationConditions()
      } finally {
        optimizationInfo.submitDeletingOptimization = false
        this.closePopup('deleteOptimization')
      }
    },
    async changeDescription(value) {
      const id = this.$route.params.id
      this.body.editInfo.editLoading = true
      await this.updateTrainedAI({
        id: id,
        name: value.name,
        description: value.description
      })
      const res = await this.fetchTrainedAiDetail({
        id,
        columnIndex: this.body.pageBody.selectedColumnIndex,
        threshold: this.threshold,
        reversePositive: this.reversePositive,
        limit: this.testDatasetInfo.limit,
        offset:
          (this.testDatasetInfo.inPageNumber - 1) * this.testDatasetInfo.limit
      })
      this.trainedAi = res
      await this.loadProjectDetail(this.projectId)
      this.body.editInfo.editLoading = false
    },
    inputEditForm: function (obj) {
      const { type, form } = obj

      if (type === 'trainedAI') {
        const trainedAIList = this.projectList[this.projectId].listAIs
        const trainedAIs = trainedAIList
          .filter((trainedAI) => trainedAI.id !== this.trainedAIId)
          .reduce((result, trainedAI) => {
            result[trainedAI.id] = trainedAI
            return result
          }, {})

        this.body.editInfo.trainedAIFormValidate = trainedAIValidator(
          trainedAIs,
          form
        )
      } else if (type === 'optimization') {
        // 最適化の名前バリデーション
        const optimizations = this.body.pageBody.optimizationConditions.filter(
          (condition) => {
            return (
              condition.id !==
              this.body.pageBody.optimizationResult.optimizationId
            )
          }
        )
        this.body.pageBody.optimizationInfo.optimizationFormValidate =
          optimizationConditionsValidator(optimizations, form)
      }
    },
    async saveEditOptimizationForm({ name, description }) {
      // 最適化の名前・説明文の保存 optimizationConditionsUpdate
      const optimizationInfo = this.body.pageBody.optimizationInfo

      optimizationInfo.loadOptimizationConditionsUpdate = true

      const payload = {
        name,
        description,
        optimizationConditionsId:
          this.body.pageBody.optimizationResult.optimizationId
      }
      await this.updateOptimzationConditions(payload)
      await this.loadOptimizationConditions()

      optimizationInfo.loadOptimizationConditionsUpdate = false
    },
    async changeColumn(column) {
      const targetIndex =
        this.trainedAi.trainConfig.predictionColumn.indexOf(column)
      if (this.body.pageBody.selectedColumnIndex === targetIndex) return
      if (targetIndex === -1) return

      this.body.pageBody.selectedColumnIndex = targetIndex

      this.loadChangeColumn = true
      this.testDatasetInfo.waitTable = true
      if (this.trainedAi.summary.type === 'TIME') {
        const res = await this.fetchTimeseriesDetail({
          id: this.$route.params.id,
          targetIndex: this.body.pageBody.selectedColumnIndex
        })
        this.trainedAi.result = res
      } else {
        await this.loadFeatureImportance()
        const res = await this.fetchTrainedAiDetail({
          id: this.$route.params.id,
          columnIndex: this.body.pageBody.selectedColumnIndex,
          limit: this.testDatasetInfo.limit,
          offset:
            (this.testDatasetInfo.inPageNumber - 1) * this.testDatasetInfo.limit
        })
        this.trainedAi = res
      }
      // 回帰の場合はグラフを取得する
      if (this.trainedAi.summary.type === 'REGRESSION') {
        await this.loadRegressionGraph()
      }
      this.threshold = 0.5
      this.reversePositive = false
      this.loadChangeColumn = false
      this.testDatasetInfo.waitTable = false
    },
    // 最適化の条件の一覧を取得
    async loadOptimizationConditions() {
      const res = await this.fetchOptimizationConditionsList(this.trainedAIId)
      this.body.pageBody.optimizationConditions = res
    },
    async loadOptimizationResult(id, config) {
      this.body.pageBody.optimizationInfo.download.downloadComp = false
      if (
        id === this.body.pageBody.optimizationResult.optimizationId &&
        !config
      )
        return
      if (this.body.pageBody.optimizationInfo.loadOptimizationDetail) return

      if (!config) {
        this.body.pageBody.optimizationInfo.loadOptimizationDetail = true
        this.resetOptimizationResult()
      } else {
        this.body.pageBody.optimizationInfo.loadOptimizationPaging = true
      }

      this.body.pageBody.optimizationResult.optimizationId = id

      let payload = {
        trainedAiId: this.trainedAIId,
        optimizationConditionsId:
          this.body.pageBody.optimizationResult.optimizationId
      }

      if (config) {
        this.body.pageBody.optimizationResult.filters = config.filters
        this.body.pageBody.optimizationResult.inPageNumber = config.inPageNumber

        payload = {
          ...payload,
          limit: this.body.pageBody.optimizationResult.limit,
          resultRanges: this.body.pageBody.optimizationResult.filters,
          offset:
            (this.body.pageBody.optimizationResult.inPageNumber - 1) *
            this.body.pageBody.optimizationResult.limit
        }
      }

      const res = await this.fetchOptimizationResult(payload)

      if (!config) {
        this.body.pageBody.optimizationResult.columnRanges = res.columnRanges
      }
      this.body.pageBody.optimizationResult.result = res.result
      this.body.pageBody.optimizationResult.total = res.total

      this.body.pageBody.optimizationInfo.loadOptimizationDetail = false
      this.body.pageBody.optimizationInfo.loadOptimizationPaging = false
    },
    async loadRegressionGraph() {
      this.body.pageBody.regressionGraph.error = null
      this.body.pageBody.regressionGraph.graphDetail = {}
      this.body.pageBody.regressionGraph.selectedRegressionData = null
      this.body.pageBody.regressionGraph.loadGraph = true
      const res = await this.fetchRegressionGraph({
        trainedAiId: this.trainedAIId,
        columnIndex: this.body.pageBody.selectedColumnIndex
      })
      if (res === 27600) {
        this.body.pageBody.regressionGraph.error = this.$t(
          'trainedAi.regressionGraph.error.NOT_FOUND_RESIDUAL_GRAPH_INFO'
        )
      } else {
        this.body.pageBody.regressionGraph.graph = res
      }
      this.body.pageBody.regressionGraph.loadGraph = false
    },
    async loadRegressionGraphDetail(event) {
      this.body.pageBody.regressionGraph.loadGraphDetail = true
      this.body.pageBody.regressionGraph.selectedRegressionData = event.index
      this.body.pageBody.regressionGraph.graphDetail = {}
      const res = await this.fetchRegressionGraphDetail({
        trainedAiId: this.trainedAIId,
        columnIndex: this.body.pageBody.selectedColumnIndex,
        index: event.index
      })
      this.body.pageBody.regressionGraph.graphDetail = res
      this.body.pageBody.regressionGraph.loadGraphDetail = false
    },
    resetOptimizationResult() {
      const setItem = {
        optimizationId: null,
        result: {},
        columnRanges: [],
        filters: [],
        limit: 50,
        total: 120,
        inPageNumber: 1
      }
      this.body.pageBody.optimizationResult = setItem
    },
    async changePageOptimization({ id, page }) {
      const config = {
        inPageNumber: page,
        filters: this.body.pageBody.optimizationResult.filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async changePageTestDataset(index) {
      if (this.testDatasetInfo.waitTable) return

      try {
        this.testDatasetInfo.waitTable = true
        this.testDatasetInfo.inPageNumber = index

        // 学習済みAI詳細情報に、テスト結果の情報が含まれている
        this.trainedAi = await this.fetchTrainedAiDetail({
          id: this.$route.params.id,
          columnIndex: this.body.pageBody.selectedColumnIndex,
          threshold: this.threshold,
          reversePositive: this.reversePositive,
          limit: this.testDatasetInfo.limit,
          offset: (index - 1) * this.testDatasetInfo.limit
        })
      } finally {
        this.$nextTick(() => {
          this.testDatasetInfo.waitTable = false
        })
      }
    },
    async changeFilterValue({ id, filter }) {
      const filters = this.body.pageBody.optimizationResult.filters
      const checkSettedFilter = filters.findIndex((item) => {
        return item.name === filter.name
      })

      if (checkSettedFilter >= 0) {
        filters[checkSettedFilter] = filter
      } else {
        filters.push(filter)
      }

      const config = {
        inPageNumber: 1,
        filters: filters
      }
      await this.loadOptimizationResult(id, config)
    },
    async downloadResult(encoding) {
      if (this.trainedAi?.summary?.type === 'CONVERT_DATASET') {
        this.downloadResultConvert(encoding)
      } else {
        this.downloadResultOptimization(encoding)
      }
    },
    async downloadResultConvert(encoding) {
      if (this.body.pageBody.convertDatasetInfo.download.downloading) return
      this.body.pageBody.convertDatasetInfo.download.downloading = true
      this.body.pageBody.convertDatasetInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.body.pageBody.convertDatasetInfo.download.downloadLoading = true
        }.bind(this),
        100
      )

      const payload = {
        encoding: encoding,
        trainedAiId: this.trainedAIId,
        targetIndex: 0
      }

      try {
        this.downloadConvertDatasetResult(payload)
      } finally {
        this.body.pageBody.convertDatasetInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.body.pageBody.convertDatasetInfo.download.downloadLoading = false
        this.body.pageBody.convertDatasetInfo.download.downloadComp = true
      }
    },
    async downloadTestDataset(encoding) {
      if (this.testDatasetInfo.download.downloading) return
      this.testDatasetInfo.download.downloading = true
      this.testDatasetInfo.download.downloadComp = false

      const payload = {
        trainedAiId: this.trainedAIId,
        columnIndex: this.body.pageBody.selectedColumnIndex,
        encoding: encoding,
        isImage: this.isImageType
      }

      try {
        await this.downloadTestDatasetResult(payload)
      } finally {
        this.testDatasetInfo.download.downloading = false
        this.testDatasetInfo.download.downloadLoading = false
        this.testDatasetInfo.download.downloadComp = true
      }
    },
    async downloadClustering({ type, encoding }) {
      const clusteringSetting = this.body.pageBody.clusteringSetting
      if (clusteringSetting.download.downloading) return
      clusteringSetting.download.downloading = true
      clusteringSetting.download.downloadComp = false

      const payload = {
        id: this.trainedAIId,
        encoding: encoding
      }

      if (type === 'filtered') {
        payload.resultId = clusteringSetting.setting.resultId
      }

      try {
        await this.downloadClusteringResult({ type, payload })
      } finally {
        clusteringSetting.download.downloading = false
        clusteringSetting.download.downloadLoading = false
        clusteringSetting.download.downloadComp = true
      }
    },
    async downloadConfusionMatrix(e) {
      const payload = {
        action: 'downloadConfusionMatrix',
        trainedAiId: this.trainedAIId,
        columnIndex: this.body.pageBody.selectedColumnIndex,
        encoding: e.encoding,
        reversePositive: this.reversePositive
      }
      const res = await this.$sendMessageAndReceive(payload)
      const decoded = base64ToBytes(res.file)
      download(decoded, 'confusion_matrix.csv')
    },
    async changeThreshold(threshold) {
      this.threshold = threshold
    },
    async changePositive(reversePositive) {
      this.reversePositive = reversePositive
    },
    async downloadResultOptimization(encoding) {
      if (this.body.pageBody.optimizationInfo.download.downloading) return
      this.body.pageBody.optimizationInfo.download.downloading = true
      this.body.pageBody.optimizationInfo.download.downloadComp = false

      // すぐにダウンロードできるものは、黒い背景が一瞬しか見えないので、setTimeoutを入れている
      this.timeoutDownload = window.setTimeout(
        function () {
          this.body.pageBody.optimizationInfo.download.downloadLoading = true
        }.bind(this),
        100
      )

      const payload = {
        trainedAiId: this.trainedAIId,
        optimizationConditionsId:
          this.body.pageBody.optimizationResult.optimizationId,
        encoding: encoding
      }

      try {
        this.downloadOptimizationResult(payload)
      } finally {
        this.body.pageBody.optimizationInfo.download.downloading = false
        window.clearTimeout(this.timeoutDownload)
        this.body.pageBody.optimizationInfo.download.downloadLoading = false
        this.body.pageBody.optimizationInfo.download.downloadComp = true
      }
    },
    // ダッシュボードから遷移してきた場合の処理
    async textMiningFromDashboadSetting(textMiningType) {
      let pageType = this.body.pageBody.textMining.activeTab
      pageType = textMiningType
      this.body.pageBody.activeTab = 'textMining'
      this.changeTabTextMining(pageType)
      const value = this.body.pageBody.textMining.params[pageType]
      if (pageType !== TEXT_MINING_PAGE_TYPE.CO_OCCURRENCE) {
        const columnName = this.body.pageBody.textMining.params.columnName
        await this.loadTextMining({ pageType, value, columnName })
      }
    },

    // テキストマイニングのパラメータの初期値設定
    setTextMiningParams() {
      this.body.pageBody.textMining.columns =
        this.trainedAi.summary?.textMining?.columns ?? []
      const body = this.body.pageBody.textMining
      const params = body.params

      // 感情分析がある場合は、ソート条件を感情分析の信頼度にする
      if (
        this.trainedAi.summary?.textMining.typeList[TEXT_MINING_TYPE.SENTIMENTS]
      ) {
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sentiments = 'negative'
        params[TEXT_MINING_PAGE_TYPE.TEXT_LIST].sortTarget = 'proba'
      }

      // CSVでの学習の場合自然言語の列の0番目を取得する情報の初期値としてセットする
      if (body.columns && body.columns.length > 0) {
        params.columnName = body.columns[0]
      }
    },
    // 各種テキストマイニングの結果取得
    async loadTextMining({ pageType, value, columnName }) {
      const textMiningTarget = this.body.pageBody.textMining
      if (textMiningTarget.waitTextMiningLoading) return

      textMiningTarget.waitTextMiningLoading = true

      // 頻度表示またはワードクラウドの画面で列名以外の変更があった場合はアクションを叩かない
      if (
        pageType === TEXT_MINING_PAGE_TYPE.FREQUENCIES_WORDCLOUD &&
        value.count !== textMiningTarget.params[pageType].count
      ) {
        textMiningTarget.params[pageType] = value
        textMiningTarget.waitTextMiningLoading = false
        return
      }

      // テキストごとのトピックを見る際にどのテキストを使用するのか、指定がない場合は0番目のテキストの結果をパラメータにする
      if (
        pageType === TEXT_MINING_PAGE_TYPE.TEXT_TOPIC &&
        value.targetName == null
      ) {
        if (
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST]?.datas == null ||
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas.length === 0
        ) {
          const textListLoadTypes =
            TEXT_MINING_PAGE_TYPE_TO_TYPE[TEXT_MINING_PAGE_TYPE.TEXT_LIST]
          const params =
            textMiningTarget.params[TEXT_MINING_PAGE_TYPE.TEXT_LIST]
          const textList = await this.getTrainedTextMining({
            loadType: textListLoadTypes[0],
            payload: {
              ...params,
              columnName,
              id: this.trainedAIId
            }
          })
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST] = textList
        }
        value.targetName =
          textMiningTarget[TEXT_MINING_PAGE_TYPE.TEXT_LIST].datas[0].fileName
      }
      const loadTypes = TEXT_MINING_PAGE_TYPE_TO_TYPE[pageType]

      for (const loadType of loadTypes) {
        // パラメータに変更がない場合かつ、該当の結果がある場合、アクションを叩かない
        if (
          columnName === textMiningTarget.params.columnName &&
          !(textMiningTarget[loadType] == null)
        ) {
          const checkDifferent = Object.entries(
            textMiningTarget.params[pageType]
          ).some(([key, val]) => {
            return value[key] !== val
          })
          if (!checkDifferent) continue
        }
        textMiningTarget[loadType] = null
        const res = await this.getTrainedTextMining({
          loadType,
          payload: {
            ...value,
            columnName,
            id: this.trainedAIId
          }
        })
        textMiningTarget[loadType] = res
      }
      textMiningTarget.params[pageType] = value
      textMiningTarget.params.columnName = columnName
      textMiningTarget.waitTextMiningLoading = false
    },
    // テキストマイニングのサイドバーの切り替え
    changeTabTextMining(pageType) {
      this.body.pageBody.textMining.activeTab = pageType
    },
    inputClusteringSetting({ value, type }) {
      const settingTarget = this.body.pageBody.clusteringSetting.setting
      switch (type) {
        case 'changeClass':
          if (settingTarget.resultId === value) return
          settingTarget.resultId = value
          settingTarget.inPageNumber = 1
          break
        case 'changeSort':
          if (settingTarget.sort === value) return
          settingTarget.sort = value
          settingTarget.inPageNumber = 1
          break
        case 'changePage':
          if (settingTarget.inPageNumber === value) return
          settingTarget.inPageNumber = value
          break
      }
      if (value != null && value !== '') {
        this.loadClusteringResult(type)
      }
    },
    async loadClusteringResult(type) {
      const clusteringSetting = this.body.pageBody.clusteringSetting.setting

      this.body.pageBody.clusteringSetting.loadingTable = true

      const offset =
        clusteringSetting.inPageNumber * clusteringSetting.limit -
        clusteringSetting.limit
      const payload = {
        id: this.trainedAIId,
        resultId: clusteringSetting.resultId,
        offset,
        limit: clusteringSetting.limit,
        sort: clusteringSetting.sort
      }
      this.body.pageBody.clusteringResult = await this.fetchClusteringResult(
        payload
      )

      if (type === 'changeClass') {
        const distributionTarget =
          this.body.pageBody.clusteringSetting.distributionSetting

        const array = [...Array(3)].map((_, i) => i)
        await Promise.all(
          array.map(async (index) => {
            if (
              distributionTarget[index].xTarget != null &&
              distributionTarget[index].yTarget != null
            ) {
              const func = await this.loadClusteringDistribution(index)
              return func
            } else {
              return null
            }
          })
        )
      }

      this.body.pageBody.clusteringSetting.loadingTable = false
    },
    changeClusteringDistributionColumn({ value, target, index }) {
      const clusteringSetting = this.body.pageBody.clusteringSetting

      const firstCheck =
        clusteringSetting.distributionSetting[index][target] !== value
      clusteringSetting.distributionSetting[index][target] = value

      if (
        clusteringSetting.distributionSetting[index].xTarget == null &&
        clusteringSetting.distributionSetting[index].yTarget == null
      )
        return

      let checkX = false
      let checkY = false
      if (clusteringSetting.setting.showDimension) {
        checkX = this.trainedAi.summary.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].xTarget
        )
        checkY = this.trainedAi.summary.clusteringInfo.dimensionList.includes(
          clusteringSetting.distributionSetting[index].yTarget
        )
      } else {
        checkX =
          this.trainedAi.summary.clusteringInfo.distributionInputList.includes(
            clusteringSetting.distributionSetting[index].xTarget
          )
        checkY =
          this.trainedAi.summary.clusteringInfo.distributionInputList.includes(
            clusteringSetting.distributionSetting[index].yTarget
          )
      }

      if (checkX && checkY && firstCheck) {
        this.loadClusteringDistribution(index)
      }
    },
    toggleClusteringShowDimension(bool) {
      this.body.pageBody.clusteringSetting.setting.showDimension = bool
      // 次元を表示するかどうかで、散布図の表示時に選択できる項目がかわるので、散布図の表示をリセットする
      this.body.pageBody.clusteringDistributions.splice(0)
      this.body.pageBody.clusteringSetting.distributionSetting =
        this.body.pageBody.clusteringSetting.distributionSetting.map(
          (setting) => {
            return {
              xTarget: null,
              yTarget: null
            }
          }
        )
    },
    async loadClusteringDistribution(index) {
      const clusteringSetting = this.body.pageBody.clusteringSetting

      this.body.pageBody.clusteringSetting.loadingDistribution = true

      const payload = {
        id: this.trainedAIId,
        resultId: clusteringSetting.setting.resultId,
        xTarget: clusteringSetting.distributionSetting[index].xTarget,
        yTarget: clusteringSetting.distributionSetting[index].yTarget
      }
      const res = await this.fetchClusteringDistribution(payload)
      this.$set(this.body.pageBody.clusteringDistributions, index, res)

      this.body.pageBody.clusteringSetting.loadingDistribution = false
    },
    async loadInferenceListInfo() {
      this.body.pageBody.inferenceListInfo.loading = true
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'getTrainedAIInferenceList',
          trainedAiId: this.$route.params.id
        })
        if (res.status !== 'error') {
          this.body.pageBody.inferenceListInfo.inferenceList = res.data
        }
      } finally {
        this.body.pageBody.inferenceListInfo.loading = false
      }
    },
    async showInferenceResult(e) {
      console.log(e)
      this.$router.push({
        name: 'inferenceResult',
        params: {
          projectId: this.projectId,
          inferenceId: e.inference.id
        }
      })
    }
  },
  watch: {
    async threshold(threshold) {
      this.loadChangeColumn = true
      try {
        this.trainedAi = await this.fetchTrainedAiDetail({
          id: this.$route.params.id,
          columnIndex: this.body.pageBody.selectedColumnIndex,
          threshold: threshold,
          reversePositive: this.reversePositive,
          limit: this.testDatasetInfo.limit,
          offset:
            (this.testDatasetInfo.inPageNumber - 1) * this.testDatasetInfo.limit
        })
      } finally {
        this.loadChangeColumn = false
      }
    },
    async reversePositive(reversePositive) {
      this.loadChangeColumn = true
      try {
        this.trainedAi = await this.fetchTrainedAiDetail({
          id: this.$route.params.id,
          columnIndex: this.body.pageBody.selectedColumnIndex,
          threshold: this.threshold,
          reversePositive: reversePositive,
          limit: this.testDatasetInfo.limit,
          offset:
            (this.testDatasetInfo.inPageNumber - 1) * this.testDatasetInfo.limit
        })
      } finally {
        this.loadChangeColumn = false
      }
    }
  }
}
</script>
