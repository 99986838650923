<template>
  <div class="c-wrap">
    <sidebar-project-detail v-if="!notFound || loading" v-bind="sidebar" />
    <sidebar v-else />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="loading" />
          <page-not-found v-else-if="notFound" />
          <trained-ai-detail-main
            v-else
            v-bind="body"
            :accountInfo="accountInfo"
            :trainedAi="trainedAi"
            :featureImportanceScatter="featureImportanceScatter"
            :loadChangeColumn="loadChangeColumn"
            :checkOptimization="checkOptimization"
            :threshold="threshold"
            :reversePositive="reversePositive"
            :testDatasetInfo="testDatasetInfo"
            @change-description="$emit('change-description', $event)"
            @change-tab="$emit('change-tab', $event)"
            @input-edit-form="$emit('input-edit-form', $event)"
            @load-feature-importance="$emit('load-feature-importance')"
            @menu-click="$emit('menu-click', $event)"
            @change-column="$emit('change-column', $event)"
            @save-edit-optimization-form="
              $emit('save-edit-optimization-form', $event)
            "
            @show-detail-optimization="
              $emit('show-detail-optimization', $event)
            "
            @show-delete-optimization="
              $emit('show-delete-optimization', $event)
            "
            @change-page="$emit('change-page', $event)"
            @change-test-dataset-page="
              $emit('change-test-dataset-page', $event)
            "
            @change-filter-value="$emit('change-filter-value', $event)"
            @download-result="$emit('download-result', $event)"
            @download-test-dataset="$emit('download-test-dataset', $event)"
            @change-threshold="$emit('change-threshold', $event)"
            @reverse-positive="$emit('reverse-positive', $event)"
            @load-text-mining="$emit('load-text-mining', $event)"
            @change-tab-text-mining="$emit('change-tab-text-mining', $event)"
            @input-clustering-setting="
              $emit('input-clustering-setting', $event)
            "
            @download-clustering-result="
              $emit('download-clustering-result', $event)
            "
            @download-confusion-matrix="
              $emit('download-confusion-matrix', $event)
            "
            @change-clustering-distribution-column="
              $emit('change-clustering-distribution-column', $event)
            "
            @toggle-clustering-show-dimension="
              $emit('toggle-clustering-show-dimension', $event)
            "
            @select-regression-graph="$emit('select-regression-graph', $event)"
            @load-inference-info="$emit('load-inference-info', $event)"
            @show-inference="$emit('show-inference', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <loading-processing v-if="disableClick" />
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <trained-ai-detail-popup
      v-bind="popup"
      :trainedAi="trainedAi"
      :disableClick="disableClick"
      :checkOptimization="checkOptimization"
      :optimizationInfo="body.pageBody.optimizationInfo"
      :optimizationResult="body.pageBody.optimizationResult"
      :optimizationConditions="body.pageBody.optimizationConditions"
      @close-modal="$emit('close-modal', $event)"
      @cancel-delete="$emit('cancel-delete', $event)"
      @delete="$emit('delete', $event)"
      @delete-optimization="$emit('delete-optimization', $event)"
      @check-service="$emit('check-service')"
    />
  </div>
</template>

<script>
import sidebarProjectDetail from '@/components/organisms/sidebar-project-detail.vue'
import Sidebar from '@/components/organisms/sidebar.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import trainedAiDetailMain from '@/components/organisms/trained-ai-detail/trained-ai-detail-main.vue'
import trainedAiDetailPopup from '@/components/organisms/trained-ai-detail/trained-ai-detail-popup'
import transitionPage from '@/components/molecules/transition-page'
import Loading from '../atoms/loading.vue'
import loadingProcessing from '@/components/atoms/loading-processing.vue'
import TransitionToggleContents from '../molecules/transition-toggle-contents.vue'
import pageNotFound from '@/components/organisms/page-not-found'

export default {
  components: {
    sidebarProjectDetail,
    Sidebar,
    hoverMenu,
    headerTabs,
    trainedAiDetailMain,
    trainedAiDetailPopup,
    transitionPage,
    Loading,
    loadingProcessing,
    TransitionToggleContents,
    pageNotFound
  },
  props: {
    sidebar: Object,
    accountInfo: Object,
    headerTabs: Object,
    body: Object,
    popup: Object,
    tabItem: Object,
    trainedAi: Object,
    featureImportanceScatter: Object,
    loading: Boolean,
    notFound: Boolean,
    disableClick: Boolean,
    loadChangeColumn: Boolean,
    checkOptimization: Boolean,
    threshold: Number,
    reversePositive: Boolean,
    testDatasetInfo: Object
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: grid;
  grid-template-columns: adjustVW(240) 100vw - adjustVW(240);
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: 100%;
  margin: adjustVH($headerTabHeight) 0 0;
}

.center {
  height: 100%;
  margin: auto;
}
</style>
