<template>
  <!--
    新規データセット追加ポップアップ
  -->
  <div class="popup-add-dataset">
    <div class="popup-add-dataset-tab-block">
      <tab-list
        :tabs="tabs"
        :activeTab="visibleContents"
        @change-tab="changeTabContent"
      />
    </div>
    <div class="popup-add-dataset-used-data">
      <graph-used-data
        class="popup-add-dataset-used-data-inner"
        :size="totalDataSize"
        :maxSize="maxDataSize"
      />
    </div>
    <transition-toggle-contents>
      <!-- ↓↓ ファイルをアップロードして登録 ↓↓ -->
      <tabFile
        v-if="visibleContents === 'file'"
        v-bind="fileInputValue"
        key="file"
        :accountInfo="accountInfo"
        :datasetFormValidate="datasetFormValidate"
        :fileValidateStatus="fileValidateStatus"
        :uploadLearningDataErrors="uploadLearningDataErrors"
        :disableClick="disableClick"
        @file-clear="$emit('file-clear')"
        @input-create-form="$emit('input-create-form', $event)"
        @on-file-input="$emit('on-file-input', $event)"
        @start-uploading="$emit('start-uploading', $event)"
      />
      <tabDataSource
        v-if="visibleContents === 'outside'"
        v-bind="showDatasource"
        key="outside"
        :datasetFormValidate="datasetFormValidate"
        :datasourceList="datasourceList"
        :importLoading="importLoading"
        :importError="importError"
        :resetDatasource="resetDatasource"
        :disableClick="disableClick"
        @datasource-items="$emit('datasource-items', $event)"
        @do-import="$emit('do-import', $event)"
        @import-dataset-loading="$emit('import-dataset-loading', $event)"
        @input-create-form="$emit('input-create-form', $event)"
        @show-table="$emit('show-table', $event)"
        @test-datasource="$emit('test-datasource', $event)"
        @new-datasource="$emit('new-datasource', $event)"
        @get-and-set-sql-list="$emit('get-and-set-sql-list', $event)"
        @get-sql-list="$emit('get-sql-list', $event)"
        @show-sql-edit="$emit('show-sql-edit', $event)"
        @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
        @set-datasource="$emit('set-datasource', $event)"
      />
    </transition-toggle-contents>
  </div>
</template>

<script>
import graphUsedData from '@/components/atoms/graph-used-data'
import tabList from '@/components/molecules/tab-list'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import tabFile from './file'
import tabDataSource from './datasource'

export default {
  components: {
    graphUsedData,
    tabList,
    tabDataSource,
    tabFile,
    transitionToggleContents
  },
  data() {
    return {
      visibleContents: 'file'
    }
  },
  computed: {
    tabs() {
      return [
        {
          id: 0,
          name: this.$t('datasetList.popup.addDataset.tabs.uploadFile'), // 'ファイルをアップロード'
          value: 'file',
          iconName: 'upload'
        },
        {
          id: 1,
          name: this.$t('datasetList.popup.addDataset.tabs.fromDataSource'), // 'データソースからインポート',
          value: 'outside',
          iconName: 'datasource'
        }
      ]
    },
    maxDataSize() {
      return this.accountInfo.planDetail.maxDataSize
    },
    totalDataSize() {
      return this.accountInfo.totalDataSize
    }
  },
  props: {
    accountInfo: Object,
    datasourceList: Array,
    datasetFormValidate: Object,
    fileInputValue: Object,
    fileValidateStatus: Object,
    showDatasource: Object,
    importLoading: Boolean,
    importError: String,
    resetDatasource: Object,
    uploadLearningDataErrors: Object,
    disableClick: Boolean
  },
  methods: {
    changeTabContent(e) {
      // タブの中身の切替
      this.visibleContents = e
    }
  },
  watch: {
    datasourceInputValue: {
      // データソースを登録ボタンを有効化
      deep: true,
      handler: function (val) {
        if (
          val.type &&
          val.host &&
          val.port &&
          val.dbName &&
          val.pass &&
          val.userName
        ) {
          this.isDatasourceDisabled = false
        } else {
          this.isDatasourceDisabled = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-add-dataset {
  overflow: hidden;
  display: grid;
  grid-template-areas:
    'tab bar'
    'main main';
  grid-template-columns: adjustVW(592) adjustVW(960);
  grid-template-rows: auto 1fr;
  grid-gap: adjustVW(24);
  height: 100%;
  &-top {
    overflow: hidden;
    display: grid;
    grid-template-columns: 30.8333333333vw 50vw;
    grid-column-gap: adjustVW(24);
    height: auto;
  }
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-tab-block {
    grid-area: tab;
    width: adjustVW(592);
  }
  &-used-data {
    grid-area: bar;
    &-inner {
      height: 100%;
    }
  }
  &-contents-wrap {
    overflow: hidden;
    grid-area: main;
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-gap: adjustVW(24);
  }
  &-main {
    display: flex;
    flex-direction: column;
    &-block {
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
      &-file {
        height: 100%;
        max-height: adjustVH(320);
      }
    }
  }
  &-table {
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-file {
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-height: adjustVH(320);
      background: $background-sub;
      border-radius: adjustVW(16);
      &-on {
        background: $background-decoration;
      }
    }
    &-icon {
      margin: 0 0 $space-small;
    }
    &-text {
      &-block {
        width: 100%;
        margin: 0 0 $space-text;
        text-align: center;
      }
    }
  }
  &-datasource {
    overflow: hidden;
    height: 100%;
    &-wrap {
      width: adjustVW(592) + adjustVW(960) + adjustVW(24);
    }
    &-block {
      width: adjustVW(592);
      margin: 0 0 $space-large;
    }
    &-list {
      display: flex;
      margin: 0 0 $space-large;
    }
    &-item {
      width: adjustVW(288);
      height: 100%;
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
    &-type {
      &-list {
        display: flex;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: adjustVW(120);
        height: adjustVH(80);
        margin: 0 $space-small 0 0;
        background: $background-sub;
        border-radius: adjustVW(8);
        &:last-child {
          margin: 0;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
      }
      &-item {
        margin: 0 $space-medium 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-new {
      margin: 0 0 $space-small;
    }
  }
}

.popup-show-datasource {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-list {
    overflow-y: auto;
    height: 100%;
    padding: 0 $space-small 0 0;
    @include scrollbar;
    &-title {
      position: sticky;
      top: 0;
      margin: 0 0 $space-small;
      background: $background;
    }
  }
  &-table {
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-toggle {
    &-wrap {
      margin: $space-sub 0;
    }
    &-info {
      padding: 0 0 $space-medium;
      margin: 0 0 $space-medium;
      border-bottom: $border-sub;
    }
    &-block {
      display: flex;
      width: 100%;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
    }
    &-inner {
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-text {
      margin: 0 0 $space-sub;
      &:last-child {
        margin: 0;
      }
    }
    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(120);
      height: adjustVH(80);
      background: $background-sub;
    }
    &-setting {
      margin: 0 0 0 auto;
    }
  }
}

.popup-show-add-table {
  &-block {
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0 auto;
    }
  }
  &-check-wrap {
    overflow: auto;
    max-height: adjustVH(232);
    @include scrollbar;
  }
}
</style>
