<template>
  <div class="popup-add-dataset-contents-wrap">
    <div class="popup-add-dataset-main">
      <div
        class="popup-add-dataset-main-block popup-add-dataset-main-block-file"
      >
        <div class="popup-add-dataset-file-wrap">
          <input-dataset-box
            v-model="selectedFile"
            :errors="errors"
            @on-file-input="fileInput"
          />
        </div>
      </div>
      <div class="popup-add-dataset-main-block">
        <input-box-validation
          v-model="createDatasetName"
          :accept="validate.accept"
          :error="validate.error"
          :title="$t('datasetList.popup.addDataset.datasetName')"
          :placeholder="
            $t('datasetList.popup.addDataset.datasetNamePlaceholder')
          "
          popupView
          isNameValidation
          :nameValidationSuggests="datasetFormValidate.suggests"
        />
      </div>
      <div class="popup-add-dataset-main-block">
        <input-box
          v-model="createDatasetDescription"
          :title="$t('datasetList.popup.addDataset.description')"
          :placeholder="
            $t('datasetList.popup.addDataset.descriptionPlaceholder')
          "
          isTextArea
          isGray
        />
      </div>
      <div class="popup-add-dataset-main-block">
        <!-- fileInputValueをViewsまで上げる -->
        <button-large
          :isDisabled="
            validFile === false ||
              validate.error ||
              disableClick ||
              fileError != null
          "
          :text="$t('datasetList.popup.addDataset.file.submit')"
          @click="
            $emit('start-uploading', {
              name: createDatasetName,
              description: createDatasetDescription
            })
          "
        />
      </div>
    </div>
    <div class="popup-add-dataset-table">
      <transition-toggle-contents>
        <div v-if="showTable" class="popup-add-dataset-table-inner">
          <!-- 本来は v-bind="dummyDataset" が入ります -->
          <table-main
            v-if="preview != null && fileError == null"
            :table="preview"
            :tableColumns="columns"
            :pagingCount="5"
          />
          <texts
            v-else-if="fileError != null"
            :text="$t(`datasetList.errors.${fileError}`)"
            class="popup-add-dataset-table-error"
          />
          <texts v-else-if="selectedFile" class="popup-add-dataset-table-error">
            {{ $t('datasetList.popup.addDataset.file.noPreviewDataType') }}
          </texts>
        </div>
      </transition-toggle-contents>
    </div>
  </div>
</template>

<script>
// import { uploadViaSocket } from '@/lib/upload.js'

import buttonLarge from '@/components/atoms/button-large'
// import buttonMain from '@/components/atoms/button-main' データソースがない場合は表示（エラーが出てしまうので、コメントアウトしています）

import inputDatasetBox from '@/components/molecules/input-dataset-box'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import tableMain from '@/components/molecules/table-main'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

import { getSamples } from '@/lib/csvPreview'
import { mapGetters } from 'vuex'

export default {
  components: {
    buttonLarge,
    inputDatasetBox,
    inputBox,
    inputBoxValidation,
    tableMain,
    transitionToggleContents
  },
  data() {
    return {
      // バリデーションを決めるため、ここのコンポーネントに置きます。
      createDatasetName: '',
      createDatasetDescription: '',

      active: false,

      showTable: true,
      preview: null,
      columns: [],
      validate: {
        accept: null,
        error: null
      },
      selectedFile: null,
      fileError: null
    }
  },
  computed: {
    ...mapGetters('settings', ['rowsPerPage']),
    errors() {
      const res = []
      if (this.fileError != null) {
        return [this.fileError]
      }
      if (this.uploadLearningDataErrors.message) {
        for (const error of this.uploadLearningDataErrors.message) {
          if (error === 'aws awsbatch failed error') {
            res.push('AWSBATCH_FAILED_ERROR')
          } else {
            res.push(error)
          }
        }
      }
      return res
    },
    /**
     *
     * ここはあとで実装します
    uploadFile () {
      if (this.uploadedFile) {
        return this.uploadedFile
      } else {
        return this.selectedFile
      }
    },
    **/
    validFile() {
      return this.active === true && this.createDatasetName !== ''
    }
  },
  props: {
    accountInfo: Object,
    datasetFormValidate: Object,
    description: String,
    fileName: String,
    uploadLearningDataErrors: Object,
    disableClick: Boolean,
    resetFileValue: Boolean
  },
  methods: {
    fileInput(e) {
      this.$emit('on-file-input', e)
      this.active = true
      this.selectedFile = e
      this.createDatasetName = e.name.substr(0, e.name.lastIndexOf('.'))
      this.makePreview()
    },
    async makePreview() {
      this.preview = null
      this.columns = null
      this.fileError = null
      if (!this.selectedFile) return
      if (this.selectedFile.name.search(/\.csv$/i) === -1) return
      const {
        columns,
        entries,
        error = null
      } = await getSamples(this.selectedFile, this.rowsPerPage)
      if (error != null) {
        this.fileError = error
        return
      }
      this.preview = []
      for (const line of entries) {
        this.preview.push(line)
      }
      this.columns = columns
    },
    // this.$emit('start-uploading')
    /*
      const fileSize = this.uploadFile.size
      this.maxProgress = fileSize
      const fileName = this.uploadFile.name
      const request = {
        action: 'startUploading',
        name: this.newDataName,
        description: this.fileInputValue.description,
        fileSize: fileSize,
        fileName: fileName
      }
      this.progress = 0
      this.maxProgress = fileSize
      this.progressStatus = 'startUploading'
      this.errorMessage = ''
      const startRes = await this.$sendMessageAndReceive(request)
      const messageId = startRes.m_id
      const uploadTask = uploadViaSocket(this.$socket, this.uploadFile, { chunkSize: 1e5, header: { action: 'uploadingData', m_id: messageId } })
      // uploadingLearningData
      try {
        await Promise.all([
          uploadTask,
          this.$watchProgress(
            messageId,
            {
              getTotalProgress: (res) => {
                this.progressStatus = 'preparingData'
                this.progress = 0
                this.maxProgress = res.total
              },
              startPreparingZipData: (res) => {
                this.progressStatus = 'preparingData'
                this.progress = 0
                this.maxProgress = 1
              },
              uploadingLearningData: (res) => {
                this.progressStatus = 'uploadingLearningData'
                this.progress = res.loadedSize
                this.maxProgress = res.totalSize
              },
              startPreparingData: (res) => {
                this.progressStatus = 'preparingNumData'
                this.progress = 0
                this.maxProgress = 0
              },
              preparingData: (res) => {
                this.progress = res.progress
              },
              dataWarning: (res) => {
                this.warnings = res.warnings
              },
              finishPreparingData: (res) => {
              }
            }
          )
        ])
        this.progress = this.maxProgress
        this.progressStatus = 'finished'
        this.fetchDataList() // todo:ロード削減
        this.clearForms()
        // this.clear()
      } catch (ex) {
        this.log_info(ex)
        this.progressStatus = 'failed'
        this.errorMessage = ex.message
      }
      */
    //      await this.fetchData({ accountId: res.accountId, dataId: res.fileId, type: res.type })
    clearForms({ emit = true } = {}) {
      this.createDatasetName = ''
      this.createDatasetDescription = ''

      this.selectedFile = null
      this.fileClear({ emit })
    },
    fileClear({ emit = true } = {}) {
      if (this.$refs.uploadLearningData) {
        this.$refs.uploadLearningData.reset()
      }
      if (emit) {
        this.$emit('file-clear')
      }
    }
  },
  watch: {
    createDatasetName: function (newName) {
      this.$emit('input-create-form', {
        type: 'dataset',
        form: {
          name: newName
        }
      })
    },
    // データソースを登録ボタンを有効化
    datasourceInputValue: {
      handler: function (val) {
        if (
          val.type &&
          val.host &&
          val.port &&
          val.dbName &&
          val.pass &&
          val.userName
        ) {
          this.isDatasourceDisabled = false
        } else {
          this.isDatasourceDisabled = true
        }
      },
      deep: true
    },
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    resetFileValue(newVal) {
      if (newVal) {
        this.clearForms()
      }
    }
  },
  destroyed() {
    this.clearForms(false)
  }
}
</script>

<style lang="scss" scoped>
.popup-add-dataset {
  display: grid;
  grid-template-columns: adjustVW(592) adjustVW(960);
  grid-template-rows: adjustVH(40) calc(100% - #{adjustVW(40)});
  grid-gap: adjustVW(24);
  height: calc(100% - #{adjustVW(24)});
  &-top {
    overflow: hidden;
    display: grid;
    grid-template-columns: 30.8333333333vw 50vw;
    grid-column-gap: adjustVW(24);
    height: auto;
  }
  &-error-text {
    white-space: pre-line;
  }
  &-wrap {
    overflow: hidden;
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-tab-block {
    width: adjustVW(592);
  }
  &-contents-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-gap: adjustVW(24);
  }
  &-main {
    display: flex;
    flex-direction: column;
    &-block {
      margin: 0 0 $space-medium;
      &:last-child {
        margin: 0;
      }
      &-file {
        height: 100%;
        max-height: adjustVH(320);
      }
    }
  }
  &-table {
    height: 100%;
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
    &-error {
      white-space: pre-line;
    }
  }
  &-file {
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-height: adjustVH(320);
      background: $background-sub;
      border-radius: adjustVW(16);
      &-on {
        background: $background-decoration;
      }
    }
    &-icon {
      margin: 0 0 $space-small;
    }
    &-text {
      &-block {
        width: 100%;
        margin: 0 0 $space-text;
        text-align: center;
      }
    }
  }
  &-datasource {
    overflow: hidden;
    height: 100%;
    &-wrap {
      width: adjustVW(592) + adjustVW(960) + adjustVW(24);
    }
    &-block {
      width: adjustVW(592);
      margin: 0 0 $space-large;
    }
    &-list {
      display: flex;
      margin: 0 0 $space-large;
    }
    &-item {
      width: adjustVW(288);
      height: 100%;
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-title {
      margin: 0 0 $space-base;
    }
    &-type {
      &-list {
        display: flex;
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: adjustVW(120);
        height: adjustVH(80);
        margin: 0 $space-small 0 0;
        background: $background-sub;
        border-radius: adjustVW(8);
        &:last-child {
          margin: 0;
        }
      }
    }
    &-button {
      &-list {
        display: flex;
      }
      &-item {
        margin: 0 $space-medium 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-new {
      margin: 0 0 $space-small;
    }
  }
}

.popup-show-datasource {
  &-wrap {
    display: grid;
    grid-template-columns: adjustVW(592) adjustVW(960);
    grid-template-rows: 100%;
    grid-column-gap: adjustVW(24);
    height: 100%;
  }
  &-list {
    overflow-y: auto;
    height: 100%;
    padding: 0 $space-small 0 0;
    @include scrollbar;
    &-title {
      position: sticky;
      top: 0;
      margin: 0 0 $space-small;
      background: $background;
    }
  }
  &-table {
    padding: $space-small;
    background: $background-sub;
    border-radius: adjustVW(16);
    &-inner {
      overflow: hidden;
      height: 100%;
      padding: $space-small $space-small $space-small $space-medium;
      background: $background;
      border-radius: adjustVW(16);
      box-shadow: $box-shadow-main;
    }
  }
  &-toggle {
    &-wrap {
      margin: $space-sub 0;
    }
    &-info {
      padding: 0 0 $space-medium;
      margin: 0 0 $space-medium;
      border-bottom: $border-sub;
    }
    &-block {
      display: flex;
      width: 100%;
      margin: 0 0 $space-small;
      &:last-child {
        margin: 0;
      }
    }
    &-inner {
      margin: 0 $space-medium 0 0;
      &:last-child {
        margin: 0;
      }
    }
    &-text {
      margin: 0 0 $space-sub;
      &:last-child {
        margin: 0;
      }
    }
    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: adjustVW(120);
      height: adjustVH(80);
      background: $background-sub;
    }
    &-setting {
      margin: 0 0 0 auto;
    }
  }
}

.popup-show-add-table {
  &-block {
    margin: 0 0 $space-medium;
    &:last-child {
      margin: 0 auto;
    }
  }
  &-check-wrap {
    overflow: auto;
    max-height: adjustVH(232);
    @include scrollbar;
  }
}
</style>
