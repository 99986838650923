<template>
  <popup-array
    :popupDefs="popupDefs"
    :showPopup="showPopup"
    :noClose="disableClick"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #deleteTrainedAi>
      <trained-ai-detail-popup-delete-trained-ai
        :trainedAi="trainedAi"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete="$emit('delete', $event)"
        @check-service="$emit('check-service')"
      />
    </template>
    <template #deleteServiceCheck>
      <trained-ai-detail-popup-delete-service-check
        :trainedAi="trainedAi"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete="$emit('delete', $event)"
      />
    </template>
    <template #deleteOptimization>
      <trained-ai-detail-popup-delete-optimization
        :trainedAi="trainedAi"
        :optimizationInfo="optimizationInfo"
        :optimizationResult="optimizationResult"
        :optimizationConditions="optimizationConditions"
        :disableClick="disableClick"
        @close-modal="$emit('close-modal', $event)"
        @delete-optimization="$emit('delete-optimization', $event)"
      />
    </template>
    <template #modelDownloadError>
      <trained-ai-detail-popup-model-download-error
        @close-modal="$emit('close-modal', $event)"
      />
    </template>
  </popup-array>
</template>

<script>
import trainedAiDetailPopupDeleteTrainedAi from './trained-ai-detail-popup-delete-trained-ai'
import trainedAiDetailPopupDeleteServiceCheck from './trained-ai-detail-popup-delete-service-check'
import trainedAiDetailPopupDeleteOptimization from './trained-ai-detail-popup-delete-optimization'
import trainedAiDetailPopupModelDownloadError from './popup/model-download-error'
import { popupArray } from '@/components/molecules/popup'

export default {
  components: {
    trainedAiDetailPopupDeleteTrainedAi,
    trainedAiDetailPopupDeleteServiceCheck,
    trainedAiDetailPopupDeleteOptimization,
    trainedAiDetailPopupModelDownloadError,
    popupArray
  },
  computed: {
    popupDefs() {
      return {
        deleteTrainedAi: {
          title: this.$t('trainedAiDetails.popup.deleteConfirm')
        },
        deleteServiceCheck: {
          title: this.$t('trainedAiDetails.popup.serviceDeleteCheck.title')
        },
        deleteOptimization: {
          title: this.$t('trainedAi.optimization.delete.title')
        },
        modelDownloadError: {
          title: this.$t('alerts.modelDownloadError.title')
        }
      }
    }
  },
  props: {
    showPopup: Array,
    trainedAi: Object,
    disableClick: Boolean,
    optimizationInfo: Object,
    optimizationResult: Object,
    optimizationConditions: Array
  }
}
</script>
