<template>
  <div class="dataset-list-wrap">
    <div class="dataset-list-top">
      <dataset-list-top
        v-bind="pageTop"
        :projectId="projectId"
        :showDatasourceList="showDatasourceList"
        :checked="checked"
        :projectItems="projectItems"
        :detasetResult="detasetResult"
        :datasetLoading="datasetLoading"
        :datasourceLoading="datasourceLoading"
        :detasourceResult="detasourceResult"
        :downloadLoading="downloadLoading"
        :accountInfo="accountInfo"
        @dataset-input-search="
          datasetSearch($event.result),
          checkSearchResult($event.noSearchResults)
        "
        @datasource-input-search="
          datasourceSearch($event.result),
          checkSearchResult($event.noSearchResults)
        "
        @add-new-data="$emit('add-new-data')"
        @set-datasource="$emit('set-datasource', $event)"
        @toggle-datasource-list="$emit('toggle-datasource-list', $event)"
        @menu-click="$emit('icon-menu-click', $event)"
      />
    </div>
    <div class="dataset-list-table">
      <dataset-list-list
        v-model="checked"
        :uploadLoading="uploadLoading"
        :detasetResult="detasetResult"
        :checkedClear="checkedClear"
        :detasourceResult="detasourceResult"
        :datasetLoading="datasetLoading"
        :showDatasourceList="showDatasourceList"
        :noSearchResults="noSearchResults"
        @sort-label="$emit('sort-label', $event)"
        @show-dataset="$emit('show-dataset', $event)"
        @show-datasource="$emit('show-datasource', $event)"
      />
    </div>
  </div>
</template>

<script>
import datasetListTop from './dataset-list-top'
import datasetListList from './dataset-list-list'

export default {
  components: {
    datasetListTop,
    datasetListList
  },
  data() {
    return {
      datasetArray: null,
      datasourceArray: null,
      checked: [],
      noSearchResults: false
    }
  },
  props: {
    projectItems: Object,
    projectId: Number,
    accountInfo: Object,
    pageBody: Object,
    datasetList: Array,
    datasourceList: Array,
    checkedClear: Boolean,
    datasetLoading: Boolean,
    uploadLoading: Boolean,
    datasourceLoading: Boolean,
    showDatasourceList: Boolean,
    downloadLoading: Boolean,
    top: Object
  },
  methods: {
    datasetSearch(result) {
      this.datasetArray = result
    },
    datasourceSearch(result) {
      this.datasourceArray = result
    },
    checkSearchResult(bool) {
      this.noSearchResults = bool
    }
  },
  computed: {
    detasetResult() {
      if (!this.datasetArray) {
        return this.datasetList
      } else {
        return this.datasetArray
      }
    },
    detasourceResult() {
      if (!this.datasourceArray) {
        return this.datasourceList
      } else {
        return this.datasourceArray
      }
    },
    pageTop() {
      // ページ上部に表示する情報
      return {
        summary: {
          title: this.$t('datasetList.title'),
          iconName: 'dataset'
        },
        usageRestriction: this.top.usageRestriction
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-list-wrap {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-base;
  height: 100vh - adjustVH($headerTabHeight);
  padding: 0 $space-medium $space-medium;
}

.dataset-list-table {
  overflow: hidden;
  border-radius: adjustVW(16);
  box-shadow: $box-shadow-main;
}
</style>
