<template>
  <!--
    データセット一覧の上部
    @click="$emit('add-new-data')" 新規データセット登録ポップアップを表示
    @click="$emit('show-datasource')" 登録しているデータソース一覧ポップアップを表示
  -->
  <page-top :title="summary.title" :iconName="summary.iconName" notScroll>
    <div class="top-function-wrap">
      <transition-toggle-contents>
        <div
          v-if="!showDatasourceList"
          key="datasetList"
          class="top-function-inner"
        >
          <div v-tooltip="toolTipContents">
            <button-main
              class="top-function-button"
              :isDisabled="checkFree || checkEducation || disableUpload"
              :text="$t('datasetList.addDataset')"
              type="emphasis"
              @click="$emit('add-new-data')"
            />
          </div>

          <fuse-search
            v-if="
              (!datasetLoading || !datasourceLoading) &&
                !checkFree &&
                !checkEducation
            "
            class="top-function-search"
            :placeholder="$t('datasetList.searchDataset')"
            :value.sync="detasetResult"
            :option="option"
            @input="$emit('dataset-input-search', $event)"
          />
          <div v-if="checkFree || checkEducation" class="top-function-free">
            <texts class="top-function-free-message" color="caution" size="min">
              {{ disablePlanTips }}
              <router-link
                class="top-function-free-link"
                :to="{ name: 'changePlan' }"
              >
                {{ $t('recipeDetail.caution.free.link') }}
              </router-link>
              {{ $t('recipeDetail.caution.free.last') }}
            </texts>
          </div>
        </div>
        <div v-else key="datasourceList" class="top-function-inner">
          <div v-tooltip="toolTipContents">
            <button-main
              class="top-function-button"
              :isDisabled="checkFree || checkEducation || disableUpload"
              :text="$t('datasetList.addDatasource')"
              type="emphasis"
              @click="$emit('set-datasource', { datasourceInfo: {} })"
            />
          </div>
          <fuse-search
            v-if="
              (!datasetLoading || !datasourceLoading) &&
                !checkFree &&
                !checkEducation
            "
            class="top-function-search"
            :placeholder="$t('datasetList.searchDatasource')"
            :value.sync="detasourceResult"
            :option="option"
            @input="$emit('datasource-input-search', $event)"
          />
          <div v-if="checkFree || checkEducation" class="top-function-free">
            <texts class="top-function-free-message" color="caution" size="min">
              {{ disablePlanTips }}
              <router-link
                class="top-function-free-link"
                :to="{ name: 'changePlan' }"
              >
                {{ $t('recipeDetail.caution.free.link') }}
              </router-link>
              {{ $t('recipeDetail.caution.free.last') }}
            </texts>
          </div>
        </div>
      </transition-toggle-contents>
      <div class="top-function-menus">
        <div class="top-function-menus-inner">
          <menu-list
            :checked="checked"
            :menus="
              !showDatasourceList ? iconMenus.dataset : iconMenus.datasource
            "
            :isMenuShow="checked.length > 0"
            @menu-click="menuClick"
          />
        </div>
        <text-toggle-button
          v-model="toggleStatus"
          :text="$t('datasetList.toggleToDatasource')"
          :textOn="$t('datasetList.toggleToDataset')"
          iconName="datasource"
          @change="$emit('toggle-datasource-list', toggleStatus)"
        />
      </div>
    </div>
  </page-top>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import buttonMain from '@/components/atoms/button-main'
import textToggleButton from '@/components/atoms/text-toggle-button'
import fuseSearch from '@/components/molecules/fuse-search'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import menuList from '@/components/molecules/menu-list'
import { checkExpiredEducation } from '@/lib/misc'

export default {
  components: {
    pageTop,
    buttonMain,
    textToggleButton,
    fuseSearch,
    transitionToggleContents,
    menuList
  },
  data() {
    return {
      toggleStatus: false,
      filterText: '',
      internalAccountIdFilter: '',
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.5
          },
          {
            name: 'accountId',
            weight: 0.5
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    projectId: Number,
    summary: Object,
    tabs: Array,
    showDatasourceList: Boolean,
    checked: Array,
    datasetLoading: Boolean,
    datasourceLoading: Boolean,
    projectItems: Object,
    detasetResult: Array,
    detasourceResult: Array,
    downloadLoading: Boolean,
    accountInfo: Object,
    usageRestriction: Object
  },
  methods: {
    menuClick(event) {
      const resultEvent = {
        isDatasource: this.showDatasourceList,
        name: event.activeMenu,
        checked: this.checked
      }
      this.$emit('menu-click', resultEvent)
    },
    canDeleteDataset(item) {
      if (this.accountInfo?.organizationInfo?.role === 'admin') return true
      const dataset = this.detasetResult.find((x) => x.id === item)
      return dataset.accountId === this.accountInfo.accountId
    }
  },
  computed: {
    canPreprocessingDataset() {
      if (this.checked.length !== 1) return false
      if (this.showDatasourceList) return false
      const dataset = this.detasetResult.find((x) => x.id === this.checked[0])
      if (
        dataset?.type === 'structured' &&
        this.checkDatasetId &&
        dataset.accountId === this.accountInfo.accountId
      ) {
        return true
      } else {
        return false
      }
    },
    checkFree() {
      return this.accountInfo.plan === 'free'
    },
    checkEducation() {
      return checkExpiredEducation(this.accountInfo)
    },
    disablePlanTips() {
      if (this.checkFree) {
        return this.$t('datasetList.message.freePlanCanNotUpload')
      } else if (this.checkEducation) {
        return this.$t('datasetList.message.expiredEducationCanNotUpload')
      } else {
        return ''
      }
    },
    disableUpload: function () {
      const plan = this.accountInfo.plan
      const restriciotn = this.usageRestriction[plan]

      return !restriciotn
    },
    targetsFileSize() {
      const result = []
      this.checked.forEach((id) => {
        result.push(this.projectItems[id].size)
      })
      const total = result.reduce((sum, element) => sum + element, 0)
      return total
    },
    checkDatasetId() {
      if (this.checked.length === 0) return false
      if (this.showDatasourceList) return false
      return this.checked.every(this.canDeleteDataset)
    },
    checkDeleteDatasets() {
      if (this.showDatasourceList) return null
      const targetDatasetList = this.checked.map((x) => {
        return this.detasetResult.find((dataset) => dataset.id === x)
      })
      const notYours = targetDatasetList.some(
        (dataset) => dataset.accountId !== this.accountInfo.accountId
      )
      // admin出ない人が他の人のデータセットを消せないようにする
      if (notYours && this.accountInfo?.organizationInfo?.role !== 'admin')
        return this.$t('common.disabled.organization.otherAccountItemDelete')
      // 一つだけの場合はファイルサイズチェックなし
      if (this.checked.length === 1) return null
      // 40MBを超える場合は不可にする
      if (this.targetsFileSize > 40 * 1024 * 1024)
        return this.$t('datasetList.datasetList.deleteMultiTooLarge')
      return null // adminの場合と、自分のデータセットだけならOK
    },
    disableDownloadDataset() {
      if (this.checked.length !== 1) return false
      if (this.showDatasourceList) return false
      const targetDataset = this.detasetResult.find(
        (dataset) => dataset.id === this.checked[0]
      )
      return targetDataset.size > 10 * 1024 * 1024
    },
    preprocessingDisabledText() {
      const dataset = this.detasetResult.find((x) => x.id === this.checked[0])
      if (dataset?.type !== 'structured') {
        return this.$t('common.disabled.preprocessing.notStructured')
      } else if (dataset.accountId !== this.accountInfo.accountId) {
        return this.$t('common.disabled.preprocessing.otherAccountItem')
      } else {
        return this.$t('common.disabled.preprocessing.main')
      }
    },
    toolTipContents: function () {
      const tips = {
        content: '',
        trigger: 'hover',
        delay: { show: 200, hide: 200 },
        autoHide: false
      }
      if (this.checkFree || this.checkEducation) {
        tips.content = ''
      } else if (this.disableUpload) {
        tips.content = this.$t('datasetList.message.usageRestriction')
      }

      return tips
    },
    iconMenus() {
      // データセット管理で、データセットかデータソースを選択したときにでるメニュー
      return {
        dataset: [
          // データセット一覧に表示するアイコンメニュー
          {
            id: 0,
            iconName: 'download', // download
            value: 'download',
            isMultiple: false, // 複数選択可能かどうか
            disabled: this.downloadLoading || this.disableDownloadDataset,
            tips: {
              name: this.downloadLoading
                ? this.$t('datasetDetail.menu.downloadLoding')
                : this.$t('datasetDetail.menu.download'),
              disabledName: this.downloadLoading
                ? this.$t('datasetDetail.menu.downloadLoding')
                : this.$t('datasetDetail.menu.disabledDownload')
            }
          },
          {
            id: 1,
            iconName: 'preproc', // preprocAndEda
            value: 'preprocAndEda',
            isMultiple: false, // 複数選択可能かどうか
            disabled: !this.canPreprocessingDataset,
            tips: {
              name: this.$t('datasetDetail.menu.preprocAndEda'),
              disabledName: this.preprocessingDisabledText
            }
          } /*
          {
            id: 2,
            iconName: 'inference', // inference
            value: 'inference',
            isMultiple: false, // 複数選択可能かどうか
            tips: {
              name: this.$t('datasetDetail.menu.inference')
            }
          }, */,
          {
            id: 3,
            iconName: 'train',
            value: 'learning',
            isMultiple: false, // 複数選択可能かどうか
            disabled: this.projectId == null,
            tips: {
              name: this.$t('datasetDetail.menu.learning'),
              disabledName: this.$t('common.disabled.notInProject')
            }
          },
          {
            id: 4,
            iconName: 'projectMove', // project
            value: 'project',
            disabled:
              this.projectId == null || this.checkFree || this.checkEducation,
            isMultiple: true, // 複数選択可能かどうか
            tips: {
              name: this.$t('common.menu.projectMove'),
              disabledName: this.checkFree
                ? this.$t('datasetList.message.freePlanCanNotMoveProject')
                : this.checkEducation
                ? this.$t(
                    'datasetList.message.expiredEducationCanNotMoveProject'
                  )
                : ''
            }
          },
          {
            id: 5,
            iconName: 'projectAdd', // project
            value: 'project-create',
            disabled:
              this.projectId == null || this.checkFree || this.checkEducation,
            isMultiple: true, // 複数選択可能かどうか
            tips: {
              name: this.$t('common.menu.projectCreateMove'),
              disabledName: this.checkFree
                ? this.$t('datasetList.message.freePlanCanNotMoveProject')
                : this.checkEducation
                ? this.$t(
                    'datasetList.message.expiredEducationCanNotMoveProject'
                  )
                : ''
            }
          },
          {
            id: 6,
            iconName: 'delete', // delete
            value: 'delete',
            isMultiple: true, // 複数選択可能かどうか
            disabled: this.checkDeleteDatasets,
            tips: {
              name: this.$t('datasetDetail.menu.delete'),
              disabledName: this.checkDeleteDatasets
            }
          }
        ],
        datasource: [
          {
            id: 0,
            iconName: 'delete', // edit
            value: 'delete',
            isMultiple: true, // 複数選択可能かどうか
            tips: {
              name: this.$t('datasetDetail.menu.delete')
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-function {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-inner {
    display: flex;
  }
  &-button {
    margin: 0 $space-medium 0 0;
  }
  &-search {
    width: adjustVW(320);
  }
  &-menus {
    display: flex;
    align-items: center;
    &-inner {
      padding: 0 $space-small 0 0;
      margin: 0 $space-small 0 0;
      border-right: $border-main;
    }
  }
  &-free {
    display: flex;
    align-items: center;
    &-message {
      white-space: pre-line;
    }
    &-link {
      color: $text-caution;
      text-decoration: underline;
    }
  }
}
</style>
